<template>
  <div>
    <h3 style="margin-top: 15px" class="custom-header main-color">
      {{ $t("Upload Your Bank receipts") }}
    </h3>
    <v-divider></v-divider>
    <v-card>
      <v-form v-model="valid" ref="form">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <label>{{ $t("Child") }}:</label>
            <v-autocomplete
              :items="applicants_data"
              item-text="name"
              item-value="id"
              v-model="data.applicant_id"
              :rules="[validationRules.required]"
              @focus="onFocus()"
              solo
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <label>{{ $t("Bank Receipts") }}:</label>
            <v-file-input
              @change="readFiles"
              :rules="[validationRules.required]"
              @focus="onFocus()"
              solo
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <label>{{ $t("Notes") }}:</label>
            <v-textarea
              :placeholder="$t('Write what is this receipt for')"
              v-model="data.notes"
              :rules="[validationRules.required]"
              @focus="onFocus()"
              solo
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-btn
            @click="sendData"
            :loading="loading"
            :disabled="loading"
            class="modal-btn-save space"
            >{{ $t("Save") }}</v-btn
          >
          <v-btn class="modal-btn-cancel space" @click="close">{{ $t("Close") }}</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      valid: false,
      data: {
        applicant_id: "",
        file: "",
        notes: ""
      },
      applicants_data: []
    };
  },
  methods: {
    readFiles(event) {
      this.data.file = event;
    },
    close() {
      this.$router.push(
        {
          path: "/thankYou"
        },
        () => {}
      );
    },
    sendData() {
      this.loading = true;
      const formData = new FormData();

      formData.append("file", this.data.file);
      formData.append("applicant_id", this.data.applicant_id);
      formData.append("notes", this.data.notes);
      axios
        .post(this.getApiUrl + "/storeBankReceipt", formData)
        .then(response => {
          this.loading = false;
          if (response.data.status.error == false) {
            this.close();
          }
        });
    },
    applicants() {
      axios.get(this.getApiUrl + "/applicant/all_applicants").then(response => {
        this.applicants_data = response.data.data;
      });
    }
  },
  mounted() {
    this.applicants();
  }
};
</script>
<style lang="scss" scoped>
.space {
  margin: 10px;
  float: right;
}
</style>
